import React from 'react';
import styled from 'styled-components';

import image from '../images/main-image.jpeg'
import adidas from '../images/partners/adidas.svg';
import continental from '../images/partners/continental.svg';
import schenker from '../images/partners/db_schenker.svg';
import ricoh from '../images/partners/ricoh.svg';
import vastint from '../images/partners/vastint.svg';
import { injectIntl } from 'react-intl';
import SweetScroll from "sweet-scroll";

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  padding-top: 90px; // should match navigation heights
  background: linear-gradient(to right, rgba(0,0,0, 0.5), transparent 80%), url(${image}) no-repeat center;
  background-size: cover;
  //box-shadow: inset 0 0 0 2000px rgba(0,0,0,0.3);
  position: relative;

  @media screen and (max-width: 500px) {
    //margin-top: 30px;
  }

  @media screen and (max-width: 1367px) {
    background-position-x: 20%;
  }
`;

const Container = styled.div`
  position: relative;
  height: calc(100vh - 95px);
  max-width: 90%;
  margin: 0 auto;

  @media screen and (min-width: 1240px) {
    max-width: 1200px;
  }
`;

const CenteredContent = styled.div`
  position: absolute;
  top: calc(50vh - 245px)
`

const TextContainer = styled.div`
  height: auto;
  width: 90%;

  @media screen and (min-width: 900px) {
    width: 50%;
  }
`

const Description = styled.span`
  color: white;
  font-size: 2.5rem;

  @media screen and (max-height: 750px) and (max-width: 750px) {
    line-height: 100%;
    font-size: 2rem;
  }
`

const CTAButton = styled.button`
  font-weight: 600;
  margin-top: 80px;
  padding: 15px 25px 15px 25px;
  color: white;
  border: 2.5px solid white;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  user-select: none;
  
  &:focus{
    outline: none;
  }
  
  &:hover{
    color:#008f37;
    border: 2px solid #008f37;
  }
`;

const StyledSection = styled.section`
  width: 100%;
  height: 95px;
  position: absolute;
  bottom: 0;
  background: #f1f1f1;
  display: flex;
  align-items: center; 
`;

const StyledContainer = styled.div`
  max-width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  color: #666666;
  font-size: 1.1rem;
  @media screen and (min-width: 1240px) {
    max-width: 1200px;
  }
  
  p{
    font-weight: 600;
    margin: 0; 
    padding: 0;
  }
`;

const Images = styled.div`
  height: auto;
  width: 80%;
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  img{
    width: 33%;
    margin: 0;
    padding: 0;
   
    @media (min-width: 400px) {
      width: 27%;
    }

    @media (min-width: 450px) {
      width: 24%;
    }

    @media (min-width: 500px) {
      width: 22%;
    }

    @media (min-width: 550px) {
      width: 20%;
    }

    @media (min-width: 550px) {
      width: 17%;
    }
  }
  
  img:nth-of-type(2) {
    max-height: 65px;
  }
  
  @media screen and (min-width: 700px) {
    margin-left: 30px;
    height: 50px;
  }

  @media screen and (min-width: 1240px) {
    margin-left: 60px;
  }
`;
const scroller = new SweetScroll({
    easing: 'easeOutExpo',
});

const Landing = ({intl, en}) => {
    let text1 = "Kompleksowy";
    let text2 = "serwis techniczny";
    let text3 = "budynków komercyjnych";
    if(en){
        text1 = "Complete";
        text2 = "maintenance";
        text3 = "of commercial buildings";
    }
    return (
    <Section>
        <Container>
          <CenteredContent>
            <TextContainer>
              <Description>{text1} <b> {text2} </b> {text3} </Description>
            </TextContainer>
            <CTAButton onClick={e=>scroller.to("#o-nas")}>{intl.formatMessage({ id:"landing.button" })}</CTAButton>
          </CenteredContent>
        </Container>
        <StyledSection>
            <StyledContainer>
               <p>{intl.formatMessage({ id:"landing.trusted" })}</p>
                <Images>
                    <img src={vastint} alt="Vastint logo"/>
                    <img src={adidas} alt="Adidas logo"/>
                    <img src={continental} alt="Continental logo"/>
                    <img src={schenker} alt="DB Schenker logo"/>
                    <img src={ricoh} alt="Ricoh logo"/>
                </Images>
            </StyledContainer>
        </StyledSection>
    </Section>
    );
};

export default injectIntl(Landing);