import React from 'react';
import styled from 'styled-components';
import Title from './Title';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { injectIntl } from 'react-intl';

import adidas from '../images/partners/adidas.svg';
import continental from '../images/partners/continental.svg';
import copyGeneral from '../images/partners/copy_general.svg';
import creditAgricole from '../images/partners/credit_agricole.svg';
import schenker from '../images/partners/db_schenker.svg';
import pfizer from '../images/partners/pfizer.svg';
import randstand from '../images/partners/randstand.svg';
import ricoh from '../images/partners/ricoh.svg';
import vastint from '../images/partners/vastint.svg';
import vivus from '../images/partners/vivus.svg';
import zdrofit from '../images/partners/zdrofit.svg';

const Section = styled.section`
  width: 100%;
  background: white;
  padding-bottom: 50px;
  // turn off default styling for Slider Arrows
  .slick-prev:before, .slick-next:before {
    display: none;
  }
`;

const Container = styled.div`
  max-width: 85%;
  margin: 0 auto;

  @media (min-width: 1240px) {
    max-width: 1200px;
  }
`;

const Header = styled(Title)`
  margin-top: 25px;
  margin-bottom: 25px;
`;

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                transform: "rotate(45deg)",
                width: "30px",
                height: "30px",
                borderLeft: "3px solid transparent",
                borderRight: "3px solid #008f37",
                borderTop: "3px solid #008f37",
                borderBottom: "3px solid transparent",
                top: "calc(50% - 20px)",
            }}
            onClick={onClick}
        />
    );
};

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                transform: "rotate(45deg)",
                width: "30px",
                height: "30px",
                borderLeft: "3px solid #008f37",
                borderRight: "3px solid transparent",
                borderTop: "3px solid transparent",
                borderBottom: "3px solid #008f37",
                top: "calc(50% - 20px)",
            }}
            onClick={onClick}
        />
    );
};

const StyledImgContainer = styled.div`
    height: 100px;
    @media (min-width: 600px) {
        height: 110px;
    }
    @media (min-width: 900px) {
        height: 120px;
    }
    @media (min-width: 1240px) {
        height: 130px;
    }
`

const StyledImg = styled.div `
    margin: auto;
    width: 90%;
    height: 100%;
    background-image: url(${props => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`

const Partnerzy = ({intl}) => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 900,
              settings: {
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]
    };

    return (
    <Section id='partnerzy'>
        <Container>
            <Header section={intl.formatMessage({ id:"partners.section" })} title={intl.formatMessage({ id:"partners.title" })} position='center'/>
            <Slider {...settings}>
                <StyledImgContainer>
                    <StyledImg image={adidas} />
                </ StyledImgContainer>
                <StyledImgContainer>
                    <StyledImg image={continental} />
                </ StyledImgContainer>
                <StyledImgContainer>
                    <StyledImg image={copyGeneral} />
                </ StyledImgContainer>
                <StyledImgContainer>
                    <StyledImg image={creditAgricole} />
                </ StyledImgContainer>
                <StyledImgContainer>
                    <StyledImg image={schenker} />
                </ StyledImgContainer>
                <StyledImgContainer>
                    <StyledImg image={pfizer} />
                </ StyledImgContainer>
                <StyledImgContainer>
                    <StyledImg image={randstand} />
                </ StyledImgContainer>
                <StyledImgContainer>
                    <StyledImg image={ricoh} />
                </ StyledImgContainer>
                <StyledImgContainer>
                    <StyledImg image={vastint} />
                </ StyledImgContainer>
                <StyledImgContainer>
                    <StyledImg image={vivus} />
                </ StyledImgContainer>
                <StyledImgContainer>
                    <StyledImg image={zdrofit} />
                </ StyledImgContainer>
            </Slider>
        </Container>
    </Section>
    );
};

export default injectIntl(Partnerzy);