export default {
    //ENGLISH LOCALISATION
    "en-US": {
        //Navigation
        navigation: {
            about: "About",
            services: "Services",
            partners: "Partners",
            contact: "Contact",
        },
        //Landing
        landing: {
            message: "Complete maintenance of commercial buildings",
            button: "Learn more",
            trusted: "Trusted us:"
        },
        //About
        about: {
            title: "About us",
            paragraph1 : "For more than 15 years we provide technical maintenance of commercial buildings, guaranteeing our clients comfort and safety in their workplace.",
            paragraph2: "We employ a qualified workforce, with all necessary permissions and certificates to do the jobs they are entrusted. We direct our services at both owners of buildings as well as tenants, with a complete consistent offer for both groups. We are responsible for the complete maintenance of Business Garden Warsaw and SwedeCenter among others.",
            metersNumber: "120k  ",
            metersText: "m2 serviced office space",
            experienceNumber: "15+",
            experienceText: "years of experience",
            clientsNumber:"50+ ",
            clientsText: "satisfied business customers"
        },
        //Services
        services: {
            section: "Services",
            title: "Range of services offered",

        },
        //Partners
        partners: {
            section: "Partners",
            title: "We work with top companies",

        },
        //Footer
        footer: {
            contact: "Contact",
            registry: "Registry",
            minicars: "Our brand"

        }
    },

    //POLISH LOCALISATION
    "pl-PL": {
        //Navigation
        navigation: {
            about: "O nas",
            services: "Usługi",
            partners: "Partnerzy",
            contact: "Kontakt",
        },
        //Landing
        landing: {
            message: "Kompletny serwis budynków komercyjnych",
            button: "Dowiedz się więcej",
            trusted: "Zaufali nam:"
        },
        //About
        about: {
            title: "Kim jesteśmy",
            paragraph1 : "Od ponad 15-stu lat zajmujemy się obsługą techniczną budynków komercyjnych, zapewniając naszym klientom komfort i bezpieczeństwo miejsca pracy.",
            paragraph2: "Dysponujemy kadrą wykwalifikowanych pracowników, którzy posiadają wszelkie niezbędne uprawnienia do wykonywanych prac. Nasze usługi kierujemy zarówno do właścicieli nieruchomości, jak i najemców zapewniając spójny serwis dla obu stron. Kompleksowo obsługujemy m.in. takie obiekty jak Business Garden Warszawa czy SwedeCenter.",
            metersNumber: "120 tys.",
            metersText: "m2 obsługiwanej powierzchni biurowej",
            experienceNumber: "15+",
            experienceText: "lat doświadczenia \n na rynku",
            clientsNumber:"50+ ",
            clientsText: "zadowolonych klientów biznesowych"
        },
        //Services
        services: {
            section: "Usługi",
            title: "Zakres wykonywanych prac",

        },
        //Partners
        partners: {
            section: "Partnerzy",
            title: "Z kim współpracujemy",

        },
        //Footer
        footer: {
            contact: "Kontakt",
            registry: "Dane rejestrowe",
            minicars: "Nasza marka"

        }
    },
}
