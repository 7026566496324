import React, { Component } from 'react';
import styled from 'styled-components';
import logo from '../images/graphics_app_logo.svg';
import SweetScroll from 'sweet-scroll';
import { injectIntl } from 'react-intl';

const StyledNavigation = styled.div`
  position: absolute;
  top: 0;
  width: 100%; 
  //background: ${props => props.transparentBackground? "transparent" : "white"};
  background: white;
  z-index: 3;
  @media screen and (max-height: 820px) and (max-width: 450px){
    background: rgba(0,0,0,0.6);
  }
`;
const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  user-select: none;

  @media screen and (min-width: 500px) {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 650px) {
    display: grid;
    grid-template-columns: 25% 1fr 175px;
  }
`;

const StyledImgDiv = styled.div`
    width: 100px; 
    @media screen and (min-width: 500px) {
        margin: 0 40%;
    }
    @media screen and (min-width: 650px) {
        margin: 0;
        width: 140px; 
    }
    @media screen and (min-width: 850px) {
        width: 180px; 
    }
`

const Logo = styled.img`
  max-height: 40px;
  max-width: 115px;
  margin: 0;
  cursor: pointer;
  justify-self: start;
  @media screen and (max-width: 500px) {
      margin-top: 16px;
  }
`;

const StyledMenu = styled.nav`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style-type: none;
  justify-content: flex-end;
  
  a {
    height: 60px;
    line-height: 60px;
    color: #008f37;
    padding: 0;
    margin: 0 16px 0px 0px;
    cursor: pointer;
    position: relative;
	display: inline-block;
	outline: none;
	text-decoration: none;
	letter-spacing: 1px;
	font-weight: 400;
	text-shadow: none;
	font-size: 0.9em;

    @media screen and (max-width: 650px) {
        height: 30px;
        line-height: 30px; 
        margin: 8px 16px 8px 0;
    }
    
    &:hover{
      color: #008f37;
	  outline: none;
	  font-weight: 600;
    }
  }
  
  a:nth-of-type(1){
    width: 65px;
  }
  a:nth-of-type(2){
    width: 75px;
  }
  a:nth-of-type(3){
    width: 85px;
  }
  
  // .o-nas{
  //   font-weight: ${props => props.section==='o-nas'? '600' : '400'};
  //   color: ${props => props.section==='o-nas'? '#008f37' : '#8ce4ba'};
  // }
  //
  // .uslugi{
  //   font-weight: ${props => props.section==='uslugi'? '600' : '400'};
  //   color: ${props => props.section==='uslugi'? '#008f37' : '#8ce4ba'};
  // }
  //
  // .partnerzy{
  //   font-weight: ${props => props.section==='partnerzy'? '600' : '400'};
  //   color: ${props => props.section==='partnerzy'? '#008f37' : '#8ce4ba'};
  // }
  
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 499px) {
      margin-bottom: 16px;
  }
`

const ContactButton = styled.button`
  padding: 6px 16px;
  margin: 0px 8px 0px 0px;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 100%;
  color: white;
  border: 0.5px solid #008f37;
  border-radius: 6px;
  background: #008f37;
  cursor: pointer;
  user-select: none;
  width: 95px;
  
  &:focus{
    outline: none;
  }
  
  &:hover{
    font-weight: 600;
  }
`

const LanguageButton = styled.button`
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 400;
    line-height: 100%;
    padding: 0px 8px;
    color: #008f37;
    background: transparent;
    border: transparent;
    cursor: pointer;
    user-select: none;
    outline: none;
    &:hover, &:focus{
      color: #008f37;
	  outline: none;
	  font-weight: 600;
    }
`

const scroller = new SweetScroll({
    easing: 'easeOutExpo',
});

class Navigation extends Component{
    // state = {
    //     visibleSection: '',
    // };

    // componentDidMount(){
    //     window.addEventListener("scroll", this.determineVisibleSection);
    // }
    //
    // componentWillUnmount(){
    //     window.removeEventListener("scroll", this.determineVisibleSection);
    // }

    // determineVisibleSection = () => {
    //     const scroll = window.scrollY + 0.55 * window.innerHeight;
    //
    //     if(scroll > 4 * window.innerHeight){
    //         this.setState({visibleSection : 'kontakt'});
    //     }
    //     else if(scroll > 3 * window.innerHeight){
    //         this.setState({visibleSection : 'partnerzy'});
    //     }
    //     else if(scroll > 2 * window.innerHeight){
    //         this.setState({visibleSection : 'uslugi'});
    //     }
    //     else if(scroll > window.innerHeight){
    //         this.setState({visibleSection : 'o-nas'});
    //     }
    //     else if(scroll >= 0 * window.innerHeight){
    //         this.setState({visibleSection : ''});
    //     }
    // };

    render(){
        const {intl, changeLang} = this.props;
        return (
            <StyledNavigation>
                <Container>
                    <Logo src={logo} onClick={() => scroller.toTop(0)}/>
                    <StyledMenu >
                        <a href="#" data-hover="O nas" onClick={ e =>{e.preventDefault(); scroller.to('#o-nas');} }>
                            {intl.formatMessage({ id:"navigation.about" })}
                        </a>
                        <a href="#" data-hover="Usługi" onClick={ e =>{e.preventDefault(); scroller.to('#uslugi');} }>
                            {intl.formatMessage({ id:"navigation.services" })}
                        </a>
                        <a href="#" data-hover="Partnerzy" onClick={ e =>{e.preventDefault(); scroller.to('#partnerzy');} }>
                            {intl.formatMessage({ id:"navigation.partners" })}
                        </a>
                    </StyledMenu>
                    <StyledDiv>
                        <ContactButton onClick={ e =>{e.preventDefault(); scroller.to('#kontakt');} }>
                            {intl.formatMessage({ id:"navigation.contact" })}
                        </ContactButton>
                        <LanguageButton onClick={ e => changeLang('pl-PL') }>
                            Pl
                        </LanguageButton>
                        <LanguageButton onClick={ e => changeLang('en-US') }>
                            En
                        </LanguageButton>
                    </StyledDiv>
                </Container>
            </StyledNavigation>
        );
    }
}

export default injectIntl(Navigation);