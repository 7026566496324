import React, {Fragment} from 'react';
import Title from "./Title";
import styled from "styled-components";
import top from "../images/top-right.png";
import central from "../images/central.png";
import bottom from "../images/bottom-left.png";
import Textbox from "./Textbox";
import { injectIntl } from 'react-intl';

const Section = styled.section`
  width: 100%;
  height: auto;
  background: white;
`;
const Container = styled.div`
  max-width: 90%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding-top: 35px;
  margin: 35px auto 0px;
  -webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;

  @media screen and (min-width: 700px){ 
    margin: 70px auto 75px;
    grid-template-rows: 1fr;
    grid-template-columns: 55% 45%;
  }

  @media screen and (min-width: 1000px){ 
    margin: 70px auto 75px;
    grid-template-rows: 1fr;
    grid-template-columns: 50% 50%;
  }

  @media screen and (min-width: 1240px){
    max-width: 1200px;
  }
`;

const Header = styled(Title)`
  margin-bottom: 60px;
  
  h3{
    margin-bottom: 20px;
    text-transform: none;
  }
`;

const Paragraph = styled.p`
  text-align: left;
  line-height: 180%;
  margin-bottom: 15px;
  color: #a8a8a8;
  margin-right: auto;
  
  @media screen and (min-width: 700px){ 
    margin-right: 32px;
    max-width: 450px;
  }
`;

const Images = styled.div`
  width: 100%;
  max-width: 380px;
  height: 400px;
  position: relative;
  justify-self: center;

  @media screen and (min-width: 1000px) {
    max-width: 100%;
  }
  
  img{
    padding: 0;
    margin: 0;
  }
  //Bottom left image
  img:nth-of-type(1){
    position: absolute; 
    bottom: 0;
    left: 0;
    max-width: 165px;
    max-height: 155px;
    @media screen and (max-width: 1000px){ 
      max-width: 120px;
      max-height: 120px;
    }
  }
  
  //Central image
  img:nth-of-type(2){
    position: absolute;
    max-height: 100%;
    max-width: 250px;
    left: calc(50% - 125px);
    top: calc(50% - 125px);
    
    @media screen and (min-width: 1000px){ 
      max-width: 300px;
      left: calc(50% - 150px);
      top: calc(50% - 150px)
    }

    @media screen and (min-width: 1240px){ 
      max-width: 390px;
      left: calc(50% - 195px);
      top: calc(50% - 195px);
    }
  }
  
  //Top right image
  img:nth-of-type(3){
    position: absolute; 
    top: 0;
    right: 0;
    max-width: 165px;
    max-height: 155px;
    @media screen and (max-width: 1000px){ 
      max-width: 120px;
      max-height: 120px;
    }
  }
`;

const GreenBlock = styled.div`
  max-width: 90%;
  margin: 16px auto;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  background: #008f37;
  border-radius: 12px;
  justify-items: center;

  @media screen and (min-width: 700px){
    margin: 0 auto;
    grid-template-rows: 1fr;
    grid-template-columns: 34% repeat(2, 1fr);
  }

  @media screen and (min-width: 1240px){
    max-width: 1200px;
  }
`;

const About = ({intl, en}) => {
    return (
    <Section id="o-nas">
        <Container>
            <div>
                <Header section="APP Sp. z o.o." title={intl.formatMessage({ id:"about.title" })}/>
                <Paragraph>
                    {intl.formatMessage({ id:"about.paragraph1" })}
                </Paragraph>
                <Paragraph>
                    {intl.formatMessage({ id:"about.paragraph2" })}
                </Paragraph>
            </div>
            <Images>
                <img src={bottom} alt="Image"/>
                <img src={central} alt="Image"/>
                <img src={top} alt="Image"/>
            </Images>
        </Container>
        <GreenBlock>
            <Textbox num={intl.formatMessage({ id:"about.metersNumber" })} text={intl.formatMessage({ id:"about.metersText" })} nomargin/>
            <Textbox num={intl.formatMessage({ id:"about.experienceNumber" })} text={intl.formatMessage({ id:"about.experienceText" })} small={!en}/>
            <Textbox num={intl.formatMessage({ id:"about.clientsNumber" })} text={intl.formatMessage({ id:"about.clientsText" })}/>
        </GreenBlock>
    </Section>
    );
};

export default injectIntl(About);