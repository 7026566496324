import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.div `
  margin: 0;
  padding: 0;
  text-align: ${props => props.position === 'center' ? 'center' : 'left'};
  color: #4b4b4b;
  
  h3{
    text-transform: uppercase;
    font-size: 1.2rem;
    padding: 0;
    margin: 0 0 5px;
    font-weight: 300;
  }
  
  h2{
    font-size: 2.5rem;
    margin: 0;
    padding: 0;
    line-height: 1.4;
    font-weight: 300;
  }
`;

const Title = ({className, section, title, position}) => {
    return (
    <StyledTitle className={className} position={position}>
        <h3>{section}</h3>
        <h2>{title}</h2>
    </StyledTitle>
    );
};

export default Title;