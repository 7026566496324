import React from 'react';
import styled from 'styled-components';
import Title from './Title';
import { injectIntl } from 'react-intl';
const StyledDiv = styled.div`
  width: 90%;
  margin: 64px 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  user-select: none;

   @media (min-width: 1240px) {
    width: 1200px;
    margin: 64px auto;
  }
`

const StyledTitile = styled(Title)`
  h3 {
    text-align: center;
  }

  h2 {
    text-align: center;
    margin: 16px 0px;
  }
`

const StyledServicesDiv = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const StyledService = styled.div`
  width: 256px;
  height: 420px;
  padding: 8px;
  margin: 8px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 7px rgba(238,238,238,0.5);

  /* :hover {
    box-shadow: none;
    transition: all 0.5s;
  } */
`

const StyledImg = styled.img`
  width: 192px;
  height: 64px;
  margin: 16px;
  object-position: center;
`

const StyledServiceTitle = styled.div`
  height: 64px;
  text-align: center;
  font-size: 1.15rem;
`

const StyledServiceDescription = styled.div`
  text-align: center;
  color: #a8a8a8;
  margin: 32px 8px;
`

const ServiceCard = ({ image, title, description }) => {
  return(
  <StyledService>
    <StyledImg src={image} alt='' />
    <StyledServiceTitle>{title}</StyledServiceTitle>
    <StyledServiceDescription>{description}</StyledServiceDescription>
  </StyledService>
)}

const Services= ({ intl, services }) => {
  
  const ServicesCards = services.map(service => (
    <ServiceCard 
      key={service.title}
      image={service.image}
      title={service.title}
      description={service.description}
    />
  ))

  return (
  <StyledDiv id='uslugi'>
    <StyledTitile section={intl.formatMessage({ id:"services.section" })} title={intl.formatMessage({ id:"services.title" })} />
    <StyledServicesDiv>
      {ServicesCards}
    </StyledServicesDiv>
  </StyledDiv>
)};

export default injectIntl(Services);