import React, { Fragment } from "react"
import Helmet from "react-helmet"
import "../global.css";

import { addLocaleData, IntlProvider } from "react-intl";
import en from "react-intl/locale-data/en";
import pl from "react-intl/locale-data/pl";
import locale from "../locale/locale.js";
import Navigation from '../components/Navigation';
import Partnerzy from '../components/Partnerzy';
import Landing from '../components/Landing';
import About from '../components/About';
import Services from '../components/Services'
import Footer from '../components/Footer';
import { servicesPL } from '../data/servicesDataPL.json';
import { servicesEN } from '../data/servicesDataEN.json';
import flattenMessages from '../utils/utils';
import browserLang from 'browser-lang';




class IndexPage extends React.Component {
    state = {
        lang: "en-US",
    };

    handleLangChange = lang => {
        this.setState({ lang: lang });
    };

    componentDidMount(){
        addLocaleData([...en, ...pl]);
        const webLang = browserLang({
            languages: ['pl-PL'],
            fallback: 'en-US',
        });
        this.setState({lang : webLang});
    }

  render() {
        const{lang} = this.state;
    return (

      <IntlProvider
          locale={lang}
          messages={flattenMessages(locale[lang])}
      >
          <Fragment>
            <Helmet>
              <title>APP Sp z o.o.</title>
              <meta
                name="description"
                content="App Sp. z. o.o website"
              />
              <meta name="referrer" content="origin" />
            </Helmet>
            <Navigation changeLang={this.handleLangChange}/>
            <Landing en={lang==="en-US"}/>
            <About en={lang==="en-US"}/>
            <Services services={lang==="pl-PL"? servicesPL : servicesEN} />
            <Partnerzy/>
            <Footer/>
            </Fragment>
      </IntlProvider>
    )
  }
}

export default IndexPage
