import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%; 
  width: 100%; 
  align-items: center;
  display: flex;
  color: white;
  justify-content: center;
  @media screen and (max-width: 700px){
    flex-direction: column;
    align-items: center;
  }
  h2{
    line-height: 50px;
    margin: ${props => props.nomargin? "32px 16px" : "32px 16px"};
    font-size: 2.6rem;
    font-weight: 500;
    padding: 0;
  }
  p{
    line-height: 25px;
    font-weight: 300;
    margin: 16px 0px;
    max-width: ${props => props.nomargin? "200px" : props.small? "145px" : "auto"};
    
    @media screen and (max-width: 700px){
      text-align: center; 
    }
  }
`;

const Textbox = ({num, text, nomargin, small}) => {
    return (
    <Container nomargin={nomargin} small={small}>
        <h2>{num}</h2>
        <p>{text}</p>
    </Container>
    );
};

export default Textbox;