import React from 'react';
import styled from 'styled-components';
import logo from '../images/white_logo.svg';
import SweetScroll from "sweet-scroll";
import { injectIntl } from 'react-intl';
import miniLogo from '../images/logo_mini_auta.svg';

const Section = styled.section`
  width: 100%;
  background: #008f37;
`;

const FooterContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  height: 300px;
  display: grid;
  padding-top: 50px;
  grid-template-columns: 35% 1fr;
  grid-template-rows: 100%;
  justify-items: center;
  .logo {
    justify-self: start;
    align-self: center;
    max-width: 235px;
    margin: 0;
    padding-bottom: 50px;
    @media screen and (max-width: 824px){
      padding-bottom: 0;
      justify-self: center;
    }
  }
  
  @media screen and (max-width: 824px){
    grid-template-columns: 20% 1fr;
  }
  @media screen and (max-width: 739px){
    padding-top: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 40% 1fr;
    height: 450px;
  }
  @media screen and (max-width: 539px){
    grid-template-rows: 20% 1fr;
    height: 700px;
  }
  
`;

const TextContainer = styled.div`
  height: auto; 
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 100%;
  align-content: center;
  justify-items: center;
  color: white;
  @media screen and (max-width: 539px){
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, 1fr);
    text-align: center;
    grid-gap: 25px;
  }
  ul{
    //justify-self: end;
    list-style-type: none;
    margin: 0;
    padding: 0;
    
    .connected{
      padding: 0;
      margin: 0;
    }
    .connected:nth-of-type(1){
      margin-bottom: 30px;
      @media screen and (max-width: 539px){
        margin-bottom: 25px;
      }
    }
    .connected:nth-of-type(4){
      margin-bottom: 20px;
    }
    
    .rejestrowe {
      margin: 0;
    }
    .rejestrowe:nth-of-type(1){
      margin-bottom: 30px;
      @media screen and (max-width: 539px){
        margin-bottom: 25px;
      }
    }
    
    .cars {
      margin: 0 0 20px;
      @media screen and (max-width: 539px){
        margin-bottom: 15px;
      }
    }
    .cars:nth-of-type(1){
      padding-left: 5px;
    }
      
    a img{
      width: 155px;
      margin: 0;
    }
    a {
      padding: 0;
      margin: 0;
    }
  }
`;

const RightPanel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SmallTitle = styled.p`
  color: white; 
  font-weight: 700;
  //text-transform: uppercase;
`;

const NavigationSection = styled.div`
  width: 100%;
  height: 80px;
  background: white;
  @media screen and (max-width: 625px){
    height: 100px;
  }
`;
const FooterNavigation = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  height: 100%;
  display: grid;
  grid-template-columns: 320px 1fr;
  font-size: 0.85rem;
  align-items: center;
  user-select: none;
  overflow: hidden;
  
  @media screen and (max-width: 1210px){
    padding-left: 10px;
  }
  @media screen and (max-width: 625px){
    grid-template-rows: 50% 50%;
    grid-template-columns: 1fr;
    align-content: center;
    text-align: center;
  }
  p{
    margin: 0;
    padding-bottom: 8px;
    @media screen and (max-width: 625px){
      order: 2;
    }
  }
`;

const StyledMenu = styled.nav`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style-type: none;
  justify-content: flex-end;
  @media screen and (max-width: 625px){
    justify-content: center;
    order: 1;
    padding-top: 8px;
  }
  a {
    height: 60px;
    line-height: 60px;
    color: black;
    padding: 0;
    margin: 0 25px 0 0;
    cursor: pointer;
    position: relative;
	display: inline-block;
	outline: none;
	text-decoration: none;
	//text-transform: uppercase;
	text-shadow: none;
	font-size: 0.85rem;
    
    &:hover{
	  outline: none;
	  color: #008f37;
    }
  }
  a:last-of-type{
  @media screen and (max-width: 625px){
      margin: 0;
    }
  }
  
`;

const scroller = new SweetScroll({
    easing: 'easeOutExpo',
});
const Footer = ({intl}) => {
    return (
        <React.Fragment>
            <Section id="kontakt">
                <FooterContainer>
                    <img src={logo} alt="APPSP logo" className="logo"/>
                    <RightPanel>
                        <TextContainer>
                            <ul>
                                <li className="connected"><SmallTitle>{intl.formatMessage({ id:"footer.contact" })}</SmallTitle> </li>
                                <li className="connected">APP Sp. z o.o. </li>
                                <li className="connected">Na Skraju 73 </li>
                                <li className="connected">05-090 Raszyn </li>
                                <li className="connected">+48 500 178 301</li>
                                <li className="connected">biuro@appsp.eu</li>
                            </ul>
                            <ul>
                                <li className="rejestrowe"> <SmallTitle>{intl.formatMessage({ id:"footer.registry" })}</SmallTitle></li>
                                <li className="rejestrowe">NIP: 5222472699</li>
                                <li className="rejestrowe">REGON: 016003670</li>
                                <li className="rejestrowe">KRS: 0000059290</li>
                            </ul>
                            <ul>
                                <li className="cars"> <SmallTitle>{intl.formatMessage({ id:"footer.minicars" })}</SmallTitle></li>
                                <li className="cars"><a target="_blank" href="http://mini-auta.pl/"><img src={miniLogo} alt="MiniCars Logo"/></a></li>
                            </ul>
                        </TextContainer>
                    </RightPanel>
                </FooterContainer>
            </Section>
            <NavigationSection>
                <FooterNavigation>
                    <p>APP Sp. z o. o. 2018 All Rights Reserved</p>
                    <StyledMenu>
                        <a href="#" onClick={ e =>{e.preventDefault(); scroller.to('#o-nas');} }>
                            {intl.formatMessage({ id:"navigation.about" })}
                        </a>
                        <a href="#" onClick={ e =>{e.preventDefault(); scroller.to('#uslugi');} }>
                            {intl.formatMessage({ id:"navigation.services" })}
                        </a>
                        <a href="#" onClick={ e =>{e.preventDefault(); scroller.to('#partnerzy');} }>
                            {intl.formatMessage({ id:"navigation.partners" })}
                        </a>
                        <a href="#" onClick={ e =>{e.preventDefault(); scroller.to('#kontakt');} }>
                            {intl.formatMessage({ id:"navigation.contact" })}
                        </a>
                    </StyledMenu>
                </FooterNavigation>
            </NavigationSection>
        </React.Fragment>
    );
};

export default injectIntl(Footer);